<template>
  <div class="content">
    <div class="title-actions">
      <h5 class="title">{{$t('sliderPrimary')}}</h5>
      <div class="btns">
        <b-button variant="info" @click="create">
          <i class="fa fa-plus"></i>
        </b-button>
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-6 col-md-4 mb-3" v-for="slider in sliders" :key="slider.id">
        <div class="card w-100">
          <img :src="slider.image" class="card-img-top" :alt="slider.name">
          <div class="card-body">
            <h6 class="card-title">{{slider.title || slider.name}}</h6>
            <hr>
            <div class="d-flex justify-content-between">
              <b-button variant="info" @click="edit(slider)">
                <i class="fa fa-pencil"></i>
              </b-button>
              <b-button variant="danger" @click="destroy(slider)">
                <i class="fa fa-trash"></i>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'sliders',
      'console'
    ])
  },
  mounted() {
    document.title = `${this.$t('sliderPrimary')} - ITFA`
    if (this.sliders.length < 1) {
      this.fetchData()
    }
  },
  methods: {
    create () {
      this.$store.dispatch('SET_SLIDER', {})
      this.$router.push({ name: 'SliderAdd', params: { lang: this.lang, console: this.console } })
    },
    destroy (slider) {
      this.$store.dispatch('SET_SLIDER', slider)
      this.$router.push({ name: 'SliderDelete', params: { lang: this.lang, sliderId: slider.id } })
    },
    edit (slider) {
      this.$store.dispatch('SET_SLIDER', slider)
      this.$router.push({ name: 'SliderEdit', params: { lang: this.lang, sliderId: slider.id } })
    },
    fetchData () {
      const path = `auth/${this.lang}/${this.console}/slider/all`
      this.$axios.get(path).then((response) => {
        const data = response.data.data
        this.$store.dispatch('SET_SLIDERS', data)
      })
    }
  }
}
</script>
